<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return">
                    <el-button type="text" size="small" icon="el-icon-arrow-left" @click="back">返回</el-button>
                </p>
            </div>
        </div>
        <el-tabs type="border-card" v-model.trim="activeName">
            <el-tab-pane label="基本信息" name="tabbasic">
                <el-collapse v-model.trim="collapseItem">
                    <el-collapse-item title="基本信息" name="basicinfo">
                        <el-form ref="soForm" :model="dataSource">
                            <el-form-item>
                                <el-col :span="2" class="form-title"><span style="color:red;">*</span>货主：</el-col>
                                <el-col :span="5">
                                    <el-form-item>
                                        <span>{{dataSource.CustomerName}}</span>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item v-if="!config.isAdd">
                                <el-col :span="2" class="form-title">发货单号：</el-col>
                                <el-col :span="5">
                                    <el-form-item>
                                        <span>{{dataSource.OrderNo}}</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">标准物流订单号：</el-col>
                                <el-col :span="5">
                                    <el-form-item>
                                        <span>{{dataSource.StandardOrderNo}}</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">原单号：</el-col>
                                <el-col :span="5">
                                    <el-form-item>
                                        <span>{{dataSource.SourceOrderNo}}</span>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" class="form-title">物流订单类型：
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item prop="DisplayOrderOperationType">
                                        <DictionarySelect v-model="dataSource.OrderOperationType" datType='Int'
										dicTypeCode="OrderTypeCode" :disabled="true">
									</DictionarySelect>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>

                            <el-form-item>
                                <el-col :span="2" class="form-title" >
                                    客户编码：
                                </el-col>
                                <el-col :span="5" >
                                    <el-form-item>
                                        {{dataSource.ClientCode}}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title" >
                                    客户名称：
                                </el-col>
                                <el-col :span="5" >
                                    <el-form-item>
                                        {{dataSource.ClientName}}
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" class="form-title">
                                    WMS创建时间：
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item>
                                        {{dataSource.ProcessTime}}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">
                                    预计发货时间：
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item>
                                        {{dataSource.ScheduledProcessDate}}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">
                                    实际发货时间：
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item>
                                        {{dataSource.DeliverGoodsTime}}
                                    </el-form-item>
                                </el-col>
                            </el-form-item>

                            <el-form-item>
                                <el-col :span="2" class="form-title">装货总物料数：</el-col>
                                <el-col :span="5">
                                    <el-form-item>
                                        {{dataSource.TotalOrderNumber}}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">装货总箱数：</el-col>
                                <el-col :span="5">
                                    <el-form-item>--
                                    </el-form-item>
                                </el-col>
                            </el-form-item>

                            <el-form-item>
                                <el-col :span="2" class="form-title">出库仓库：
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item prop="WarehouseName">
                                        {{dataSource.WarehouseName}}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">
                                    出库仓库编码：
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item prop="WarehouseNo">
                                        {{dataSource.WarehouseNo}}
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                        </el-form>
                    </el-collapse-item>

                    <el-collapse-item title="物流信息" name="logisticinfo">
                        <el-form ref="logisticForm" :model="dataSource">
                            <el-form-item>
                                <el-col :span="2" class="form-title"> 承运方式：</el-col>
								<el-col :span="5">
									<el-form-item>
										{{dataSource.DisplayDeliveryWay}}
									</el-form-item>
								</el-col>
                                <el-col :span="3" class="form-title">承运商名称：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="LogisticserName">
                                        {{dataSource.LogisticserName}}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">承运商编码：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="LogisticserCode">
                                        {{dataSource.LogisticserCode}}
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" class="form-title">
									承运商产品：
								</el-col>
								<el-col :span="5">
									<el-form-item prop="MemberProductName">
                                        {{dataSource.MemberProductName}}
									</el-form-item>
								</el-col>
                                <el-col :span="3" class="form-title">运单号：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="LogisticsNo">
                                        {{dataSource.LogisticsNo}}
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" class="form-title">
									车型：
								</el-col>
								<el-col :span="5">
									<el-form-item prop="MemberProductName">
                                        {{dataSource.MemberProductName}}
									</el-form-item>
								</el-col>
                                <el-col :span="3" class="form-title">车牌号：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="LogisticsNo">
                                        {{dataSource.LogisticsNo}}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">司机：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="LogisticsNo">
                                        {{dataSource.LogisticsNo}}
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                        </el-form>
                    </el-collapse-item>

                    <el-collapse-item title="发货人信息" name="senderinfo">
                        <el-form ref="senderForm" :model="dataSource">
                            <el-form-item>
                                <el-col :span="2" class="form-title">发货地址编码：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="SenderAddressCode">
                                    <span>{{dataSource.SenderAddressCode}}</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">发货方：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="SenderCompany">
                                    <span>{{dataSource.SenderCompany}}</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">联系人：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="SenderName">
                                    <span>{{dataSource.SenderName}}</span>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" class="form-title">联系电话：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="SenderMobile">
                                    <span>{{dataSource.SenderMobile}}</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">发货地址：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.SenderProvince+dataSource.SenderCity+dataSource.SenderCounty+dataSource.SenderAddress}}</span>
                                </el-col>
                            </el-form-item>
                            <!-- <el-form-item v-if="config.isCanEdit">
                                <el-col :span="3" class="form-title">
                                    <el-button type="primary" size="small" @click.native="preservationadd">保存至常用发货地址
                                    </el-button>
                                </el-col>
                            </el-form-item> -->
                        </el-form>
                    </el-collapse-item>
                    <el-collapse-item title="收货人信息" name="receiverinfo">
                        <el-form ref="receiverForm" :model="dataSource">
                            <el-form-item>
                                <el-col :span="2" class="form-title">收货地址编码：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="ReceiverAddressCode">
                                    <span>{{dataSource.ReceiverAddressCode}}</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">收货方：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="ReceiverCompany">
                                    <span>{{dataSource.ReceiverCompany}}</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">联系人：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="ReceiverName">
                                    <span>{{dataSource.ReceiverName}}</span>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" class="form-title">联系电话：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="ReceiverMobile">
                                    <span>{{dataSource.ReceiverMobile}}</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">收货地址：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.ProvinceName+dataSource.CityName+dataSource.CountyName+dataSource.ReceiverAddress}}</span>
                                </el-col>
                            </el-form-item>
                        </el-form>
                    </el-collapse-item>
					
					<el-collapse-item title="商品明细" name="productinfo">
				<div class="sl-border">
				    <el-table v-loading="loadding" ref="ltsListTable" :data="OrderProductPage.Result" border highlight-current-row>
				        <el-table-column type="index" width="50">
				        </el-table-column>
				        <el-table-column label="商品编码" min-width="120px">
				            <template slot-scope="scope">
				                <span v-model.trim="scope.row.ProductNo">{{scope.row.ProductNo}}</span>
				            </template>
				        </el-table-column>
				        <el-table-column label="外部编码" min-width="110px">
				            <template slot-scope="scope">
				                <span v-model.trim="scope.row.OuterID">{{scope.row.OuterID}}</span>
				            </template>
				        </el-table-column>
				        <el-table-column label="组合商品编码" min-width="110px">
				            <template slot-scope="scope">
				                <span v-model.trim="scope.row.ProductCombineNo">{{scope.row.ProductCombineNo}}</span>
				            </template>
				        </el-table-column>
				        <el-table-column label="商品名称" min-width="160px">
				            <template slot-scope="scope">
				                <span v-model.trim="scope.row.ProductName">{{scope.row.ProductName}}</span>
				            </template>
				        </el-table-column>
				        <el-table-column label="规格" min-width="100px">
				            <template slot-scope="scope">
				                <span v-model.trim="scope.row.SKU">{{scope.row.SKU}}</span>
				            </template>
				        </el-table-column>
				        <el-table-column label="商品条码" min-width="130px">
				            <template slot-scope="scope">
				                <span v-model.trim="scope.row.ProductBarCode">{{scope.row.ProductBarCode}}</span>
				            </template>
				        </el-table-column>
				        <el-table-column label="商品数量" min-width="110px">
				            <template slot-scope="scope">
				                <el-input v-if="config.isCanEdit" min="1" type="number"
				                          v-model.trim="scope.row.OrderNumber" size="mini" placeholder="数量" :maxlength="9" />
				                <span v-if="!config.isCanEdit"
				                      v-model.trim.number.trim="scope.row.OrderNumber">{{scope.row.OrderNumber}}</span>
				            </template>
				        </el-table-column>
				        <el-table-column prop="OrderProductUnit" label="商品单位" min-width="130px">
				            <template slot-scope="scope">
				                <el-select v-model.trim="scope.row.OrderProductUnit" size="mini" :maxlength="200">
				                    <el-option v-for="item in scope.row.UnitList" :key="item.ProductUnitName"
				                               :label="item.ProductUnitName" :value="item.ProductUnitName">
				                    </el-option>
				                </el-select>
				            </template>
				        </el-table-column>
				        <el-table-column prop="InventoryType" label="库存类型" min-width="130px">
				            <template slot-scope="scope">
				                <el-select v-model.trim="scope.row.InventoryType" size="mini" :maxlength="200">
				                    <el-option v-for="item in dataSource.InventoryTypes" :key="item.Value"
				                               :label="item.Label" :value="item.Value">
				                    </el-option>
				                </el-select>
				            </template>
				        </el-table-column>
				        <el-table-column label="商品单价" min-width="110px">
				            <template slot-scope="scope">
				                <el-input v-if="config.isCanEdit&&!dataSource.IsRKBanSelectPrice" v-model.trim="scope.row.ProductPrice" size="mini"
				                          placeholder="单价" :maxlength="9" />
				             <span v-if="config.isCanEdit&&dataSource.IsRKBanSelectPrice"
				             	v-model.trim="scope.row.ProductPrice">{{scope.row.ProductPrice}}</span>
								<span v-if="!config.isCanEdit"
				                      v-model.trim="scope.row.ProductPrice">{{scope.row.ProductPrice}}</span>
				            </template>
				        </el-table-column>
				
				        <!-- <el-table-column label="商品体积cm³" min-width="120px">
				            <template slot-scope="scope">
				                <el-input v-if="config.isCanEdit" min="1" type="number"
				                          v-model.trim="scope.row.EstimateTotalCubage" size="mini" placeholder="体积"
				                          :maxlength="15" />
				                <span v-if="!config.isCanEdit"
				                      v-model.trim="scope.row.EstimateTotalCubage">{{scope.row.EstimateTotalCubage}}</span>
				            </template>
				        </el-table-column> -->
				        <!-- <el-table-column label="商品重量kg" min-width="120px">
				            <template slot-scope="scope">
				                <el-input v-if="config.isCanEdit" min="1" type="number"
				                          v-model.trim="scope.row.EstimateTotalWeight" size="mini" placeholder="重量"
				                          :maxlength="15" />
				                <span v-if="!config.isCanEdit"
				                      v-model.trim="scope.row.EstimateTotalWeight">{{scope.row.EstimateTotalWeight}}</span>
				            </template>
				        </el-table-column> -->
				        <el-table-column label="生产日期" min-width="110px" v-if="proDateDisplay">
				            <template slot-scope="scope">
				                <el-date-picker v-if="config.isCanEdit" v-model.trim="scope.row.ProductDate" size="mini"
				                                type="date" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				                <span v-if="!config.isCanEdit"
				                      v-model.trim="scope.row.ProductDate">{{scope.row.ProductDate}}</span>
				            </template>
				        </el-table-column>
				        <el-table-column label="生产批次号" min-width="110px" v-if="proBatchNoDisplay">
				            <template slot-scope="scope">
				                <el-input v-if="config.isCanEdit" v-model.trim="scope.row.ProductBatchNo" size="mini"
				                          placeholder="生产批次号" :maxlength="30" />
				                <span v-if="!config.isCanEdit"
				                      v-model.trim="scope.row.ProductBatchNo">{{scope.row.ProductBatchNo}}</span>
				            </template>
				        </el-table-column>
				        <el-table-column label="序列号" min-width="110px">
				            <template slot-scope="scope">
				                <el-input v-if="config.isCanEdit" v-model.trim="scope.row.ProductSN" size="mini"
				                          placeholder="序列号" :maxlength="30" />
				                <span v-if="!config.isCanEdit"
				                      v-model.trim="scope.row.ProductSN">{{scope.row.ProductSN}}</span>
				            </template>
				        </el-table-column>
				        <!--批属性列自动生成-index从3开始，1,2使用的生产日期和批次号-->
				        <el-table-column :label="col.LotName?col.LotName:col.DefaultLotName"
				                         v-for="(col,index) in lotManages" v-if="col.IsDisplay" :key="index"
				                         min-width="140px">
				            <template slot-scope="scope">
				                <span v-if="config.isCanEdit">
				                    <el-input size="mini" v-if="col.LotType==2" v-model="scope.row['LotAttr'+(index+3)]"
				                              type="number" oninput="this.value=this.value.replace(/[^0-9]+/,'');"
				                              :placeholder="col.DefaultLotName">
				                    </el-input>
				                    <el-input size="mini" v-else-if="col.LotType==3"
				                              v-model="scope.row['LotAttr'+(index+3)]" type="number"
				                              oninput="this.value=this.value.replace(/[^0-9.]+/,'');"
				                              :placeholder="col.DefaultLotName">
				                    </el-input>
				                    <el-date-picker v-else-if="col.LotType==4" v-model="scope.row['LotAttr'+(index+3)]"
				                                    type="date" value-format="yyyy-MM-dd" :placeholder="col.DefaultLotName">
				                    </el-date-picker>
				                    <el-select v-else-if="col.LotType==5" v-model="scope.row['LotAttr'+(index+3)]"
				                               style="width: 100%;" multiple filterable allow-create default-first-option
				                               :placeholder="col.DefaultLotName">
				                        <el-option v-for="item in col.SelectList" :key="item" :label="item"
				                                   :value="item">
				                        </el-option>
				                    </el-select>
				                    <el-input size="mini" v-else v-model="scope.row['LotAttr'+(index+3)]"
				                              :placeholder="col.DefaultLotName">
				                    </el-input>
				                </span>
				                <span v-else>{{scope.row['LotAttr'+(index+3)]}}</span>
				            </template>
				        </el-table-column>
				        <el-table-column label="商品备注" min-width="150px">
				            <template slot-scope="scope">
				                <el-input v-if="config.isCanEdit" v-model.trim="scope.row.ProductMemo" size="mini"
				                          placeholder="商品备注" :maxlength="50" />
				                <span v-if="!config.isCanEdit"
				                      v-model.trim="scope.row.ProductMemo">{{scope.row.ProductMemo}}</span>
				            </template>
				        </el-table-column>
				    </el-table>
				    <div style="float: left;margin-top: 5px;">
				        商品共计：{{productNum}}件；总毛重：{{productGrossWeight}}kg；总净重：{{productNetWeight}}kg；总金额：{{productAmount}}元；总体积：{{productVolume}}cm³
				    </div>
				    <div class="page">
				        <el-pagination @size-change="productPageSizeChange"
				            @current-change="productCurrentChange" :current-page="OrderProductPage.PageIndex"
				            :page-sizes="[10, 30, 50, 100]" :page-size="OrderProductPage.PageSize"
				            layout="total, sizes, prev, pager, next, jumper"
				            :total="OrderProductPage.TotalCount">
				        </el-pagination>
				        <el-button v-if="!config.isCanEdit" type="text" class="btn-refurbish" @click="onProductRefresh">
				            刷新
				        </el-button>
				    </div>
				</div>
					</el-collapse-item>
					
					
					
                </el-collapse>
            </el-tab-pane>
            <el-tab-pane label="日志信息" name="tabsOrderLogs" v-if="false">
                <el-table :data="dataSource.OrderLogs" border highlight-current-row>
                    <el-table-column prop="DisplayCreatedTime" label="创建日期">
                        <template slot-scope="scope">
                            <span v-model.trim="scope.row.CreatedTime">{{scope.row.CreatedTime}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="OperateDesc" label="操作描述">
                        <template slot-scope="scope">
                            <span v-model.trim="scope.row.OperateDesc">{{scope.row.OperateDesc}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="OperatorName" label="操作人">
                        <template slot-scope="scope">
                            <span v-model.trim="scope.row.OperateUserName">{{scope.row.OperateUserName}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <el-tabs type="border-card" v-model.trim="tab2activeName" v-if="false">
            <el-tab-pane label="商品明细" name="tabOrderProduct">
                <div class="sl-border">
                    <el-table v-loading="loadding" ref="ltsListTable" :data="OrderProductPage.Result" border highlight-current-row>
                        <el-table-column type="index" width="50">
                        </el-table-column>
                        <el-table-column label="商品编码" min-width="120px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ProductNo">{{scope.row.ProductNo}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="外部编码" min-width="110px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.OuterID">{{scope.row.OuterID}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="组合商品编码" min-width="110px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ProductCombineNo">{{scope.row.ProductCombineNo}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品名称" min-width="160px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ProductName">{{scope.row.ProductName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="规格" min-width="100px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.SKU">{{scope.row.SKU}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品条码" min-width="130px">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ProductBarCode">{{scope.row.ProductBarCode}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品数量" min-width="110px">
                            <template slot-scope="scope">
                                <el-input v-if="config.isCanEdit" min="1" type="number"
                                          v-model.trim="scope.row.OrderNumber" size="mini" placeholder="数量" :maxlength="9" />
                                <span v-if="!config.isCanEdit"
                                      v-model.trim.number.trim="scope.row.OrderNumber">{{scope.row.OrderNumber}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="OrderProductUnit" label="商品单位" min-width="130px">
                            <template slot-scope="scope">
                                <el-select v-model.trim="scope.row.OrderProductUnit" size="mini" :maxlength="200">
                                    <el-option v-for="item in scope.row.UnitList" :key="item.ProductUnitName"
                                               :label="item.ProductUnitName" :value="item.ProductUnitName">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column prop="InventoryType" label="库存类型" min-width="130px">
                            <template slot-scope="scope">
                                <el-select v-model.trim="scope.row.InventoryType" size="mini" :maxlength="200">
                                    <el-option v-for="item in dataSource.InventoryTypes" :key="item.Value"
                                               :label="item.Label" :value="item.Value">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品单价" min-width="110px">
                            <template slot-scope="scope">
                                <el-input v-if="config.isCanEdit&&!dataSource.IsRKBanSelectPrice" v-model.trim="scope.row.ProductPrice" size="mini"
                                          placeholder="单价" :maxlength="9" />
                             <span v-if="config.isCanEdit&&dataSource.IsRKBanSelectPrice"
                             	v-model.trim="scope.row.ProductPrice">{{scope.row.ProductPrice}}</span>
								<span v-if="!config.isCanEdit"
                                      v-model.trim="scope.row.ProductPrice">{{scope.row.ProductPrice}}</span>
                            </template>
                        </el-table-column>

                        <!-- <el-table-column label="商品体积cm³" min-width="120px">
                            <template slot-scope="scope">
                                <el-input v-if="config.isCanEdit" min="1" type="number"
                                          v-model.trim="scope.row.EstimateTotalCubage" size="mini" placeholder="体积"
                                          :maxlength="15" />
                                <span v-if="!config.isCanEdit"
                                      v-model.trim="scope.row.EstimateTotalCubage">{{scope.row.EstimateTotalCubage}}</span>
                            </template>
                        </el-table-column> -->
                        <!-- <el-table-column label="商品重量kg" min-width="120px">
                            <template slot-scope="scope">
                                <el-input v-if="config.isCanEdit" min="1" type="number"
                                          v-model.trim="scope.row.EstimateTotalWeight" size="mini" placeholder="重量"
                                          :maxlength="15" />
                                <span v-if="!config.isCanEdit"
                                      v-model.trim="scope.row.EstimateTotalWeight">{{scope.row.EstimateTotalWeight}}</span>
                            </template>
                        </el-table-column> -->
                        <el-table-column label="生产日期" min-width="110px" v-if="proDateDisplay">
                            <template slot-scope="scope">
                                <el-date-picker v-if="config.isCanEdit" v-model.trim="scope.row.ProductDate" size="mini"
                                                type="date" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                                <span v-if="!config.isCanEdit"
                                      v-model.trim="scope.row.ProductDate">{{scope.row.ProductDate}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="生产批次号" min-width="110px" v-if="proBatchNoDisplay">
                            <template slot-scope="scope">
                                <el-input v-if="config.isCanEdit" v-model.trim="scope.row.ProductBatchNo" size="mini"
                                          placeholder="生产批次号" :maxlength="30" />
                                <span v-if="!config.isCanEdit"
                                      v-model.trim="scope.row.ProductBatchNo">{{scope.row.ProductBatchNo}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="序列号" min-width="110px">
                            <template slot-scope="scope">
                                <el-input v-if="config.isCanEdit" v-model.trim="scope.row.ProductSN" size="mini"
                                          placeholder="序列号" :maxlength="30" />
                                <span v-if="!config.isCanEdit"
                                      v-model.trim="scope.row.ProductSN">{{scope.row.ProductSN}}</span>
                            </template>
                        </el-table-column>
                        <!--批属性列自动生成-index从3开始，1,2使用的生产日期和批次号-->
                        <el-table-column :label="col.LotName?col.LotName:col.DefaultLotName"
                                         v-for="(col,index) in lotManages" v-if="col.IsDisplay" :key="index"
                                         min-width="140px">
                            <template slot-scope="scope">
                                <span v-if="config.isCanEdit">
                                    <el-input size="mini" v-if="col.LotType==2" v-model="scope.row['LotAttr'+(index+3)]"
                                              type="number" oninput="this.value=this.value.replace(/[^0-9]+/,'');"
                                              :placeholder="col.DefaultLotName">
                                    </el-input>
                                    <el-input size="mini" v-else-if="col.LotType==3"
                                              v-model="scope.row['LotAttr'+(index+3)]" type="number"
                                              oninput="this.value=this.value.replace(/[^0-9.]+/,'');"
                                              :placeholder="col.DefaultLotName">
                                    </el-input>
                                    <el-date-picker v-else-if="col.LotType==4" v-model="scope.row['LotAttr'+(index+3)]"
                                                    type="date" value-format="yyyy-MM-dd" :placeholder="col.DefaultLotName">
                                    </el-date-picker>
                                    <el-select v-else-if="col.LotType==5" v-model="scope.row['LotAttr'+(index+3)]"
                                               style="width: 100%;" multiple filterable allow-create default-first-option
                                               :placeholder="col.DefaultLotName">
                                        <el-option v-for="item in col.SelectList" :key="item" :label="item"
                                                   :value="item">
                                        </el-option>
                                    </el-select>
                                    <el-input size="mini" v-else v-model="scope.row['LotAttr'+(index+3)]"
                                              :placeholder="col.DefaultLotName">
                                    </el-input>
                                </span>
                                <span v-else>{{scope.row['LotAttr'+(index+3)]}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品备注" min-width="150px">
                            <template slot-scope="scope">
                                <el-input v-if="config.isCanEdit" v-model.trim="scope.row.ProductMemo" size="mini"
                                          placeholder="商品备注" :maxlength="50" />
                                <span v-if="!config.isCanEdit"
                                      v-model.trim="scope.row.ProductMemo">{{scope.row.ProductMemo}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="float: left;margin-top: 5px;">
                        商品共计：{{productNum}}件；总毛重：{{productGrossWeight}}kg；总净重：{{productNetWeight}}kg；总金额：{{productAmount}}元；总体积：{{productVolume}}cm³
                    </div>
                    <div class="page">
                        <el-pagination @size-change="productPageSizeChange"
                            @current-change="productCurrentChange" :current-page="OrderProductPage.PageIndex"
                            :page-sizes="[10, 30, 50, 100]" :page-size="OrderProductPage.PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="OrderProductPage.TotalCount">
                        </el-pagination>
                        <el-button v-if="!config.isCanEdit" type="text" class="btn-refurbish" @click="onProductRefresh">
                            刷新
                        </el-button>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                activeGLName: 'first',
                collapseItem: ['basicinfo','senderinfo','receiverinfo','logisticinfo','productinfo'],
                productIndex: 0,
                isSelectShop: false,
                ExternalSystemNames: [],
                QimenOrderTypes: [{
                    value: 100,
                    label: '入库单'
                }, {
                    value: 400,
                    label: '退货入库'
                }
                ],
                lotManages: [],//批属性管理数据
                proDateDisplay: false,
                proBatchNoDisplay: false,
                currentCarrierSelectedRow: {},
				currentCarrierProductSelectRow: {},
                currentWarehouseSelectedRow: {},
                currentProductSelectedRows: {},
                currentReceiverSelectedRow: {},
                activeName: 'tabbasic',
                tab2activeName: 'tabOrderProduct',
                locationFrom: 1,
                updateStatus: false,
                productNum: 0,
                productGrossWeight: 0,
                productNetWeight: 0,
                productAmount: 0,
                productVolume: 0,
                pickerDisable: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 8.64e7;
                    }
                },
                loadding: false,
                multipleSelection: [],
                initDataSource: {},
                dataSource:{
                    ReceiverSource:{
                        ProvinceName: '',
                        ProvinceID: '',
                        CityName: '',
                        CityID: '',
                        CountyName: '',
                        CountyID: '',
                    },
                    SenderSource:{
                        ProvinceName: '',
                        ProvinceID: '',
                        CityName: '',
                        CityID: '',
                        CountyName: '',
                        CountyID: '',
                    },
                    OrderExtend:{},
                },
                OrderProductPage:{PageIndex:1,PageSize:10,Result:[],OrderId:null},
                DeliveryWays: [{
						label: '自提',
						value: 100
					},
					{
						label: '快递',
						value: 200
					},
					{
						label: '快运',
						value: 300
					},
					{
						label: '城配',
						value: 400
					},
					{ label: '陆运', value: 500 },
				],
                carrierProductConfig: {
					isDetailDisplay: true,
					MemberId: 0,
					MemberCode: ''
				},
                carrierProductOptions: {
					visible: false,
					size: 'small'
				},
            }
        },
        props: {
            config: {
                isDetailDisplay: false
            },
        },
        mounted: function () {
            this.getLotManage();
        },
        computed: {
            OrderStatus() {
                return this.dataSource.OrderStatus;
            },
            OrderProductList() {
                if (this.OrderProductPage) {
                    return this.OrderProductPage.Result;
                }
                return new Array();
            }
        },
        watch: {
            'config.dataSourceKey':{
                handler(curVal, oldVal) {
                    if(curVal&&curVal>0){
                        if(curVal!=oldVal) this.initial(curVal); //初始化
                    }else{
                        //this.add(); //新增初始化
                    }
                },
                deep: true
            }
        },
        methods: {
            handleGLClick(tab, event) {
				if (tab.name == 'first') {
				} else if(tab.name == 'second')
				{
				}
				else if (tab.name == 'third') {
				} else if (tab.name == 'fourth') {
				}else if(tab.name == 'fifth')
				{
				}
			},
            //商品分页
            onProductRefresh() {
                this.productSearchPage(this.OrderProductPage);
            },
            productPageSizeChange(val) {
                this.OrderProductPage.PageSize = val;
                this.productSearchPage(this.OrderProductPage);
            },
            productCurrentChange(val) {
                this.OrderProductPage.PageIndex = val;
                this.productSearchPage(this.OrderProductPage);
            },
            productSearchPage(search) {
                var _this=this;
                var pageSearch = {
                    PageIndex: search.PageIndex,
                    PageSize: search.PageSize,
                    IsNoPage: this.config.isCanEdit,
                    ViewStatus: this.config.OrderDisplayTag,
                    Params: {
                        OrderId: search.OrderId
                    },
                };
                this.$ajax.query("omsapi/execute/getorderproductlist", "post", pageSearch, (result) => {
                          _this.OrderProductPage.Result = result.Result;
                          _this.OrderProductPage.TotalCount = result.TotalCount;
                });
            },
            //重置表单
            resetSOForm: function () {
                this.Utils.clearForm(this.dataSource);
            },
            back: function () {
                this.config.isDetailDisplay = false;
            },
            initial(id) {
                var _this = this;
                this.$ajax.send("omsapi/execute/getById", "get", { id: id }, (data) => {
                    _this.config.isAdd = false;
                    _this.config.isNormal = true;
                    _this.config.isReturn = false;
                    _this.resetSOForm();
                    _this.dataSource = data.Result;
                    // _this.$nextTick(function () {
                    // });
                });
                //商品明细分页-初始化
                // _this.OrderProductPage = {
                //         PageIndex:1,PageSize:10,Result:[]
                //     };
                _this.OrderProductPage.OrderId=id;
                _this.productSearchPage(_this.OrderProductPage);
                //初始化字段
                _this.dataSource.ReceiverSource = {};
                _this.dataSource.SenderSource = {};
                if (_this.dataSource.OrderStatus == 100) {
                    _this.config.isCanEdit = true;
                }
                else {
                    _this.config.isCanEdit = false;
                }
            },
            //批属性管理数据
            getLotManage() {
                if(!this.dataSource.CustomerCode){
                    return;
                }
                this.$ajax.send("omsapi/stockLotManage/findList?customerCode="+this.dataSource.CustomerCode, "get", {}, (data) => {
                    for (let index = 0; index < data.Result.length; index++) {
                        var lot = data.Result[index];
                        if (lot.DefaultLotProp == 'ProductDate' && lot.IsDisplay) {
                            this.proDateDisplay = true;
                        }
                        if (lot.DefaultLotProp == 'ProductBatchNo' && lot.IsDisplay) {
                            this.proBatchNoDisplay = true;
                        }
                        if (lot.Sort > 2) {//排除默认的四个属性和批属性1、2
                            this.lotManages.push(lot);
                        }
                    }
                });
            }
        },
        components: {
        }
    }
</script>